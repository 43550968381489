import Select from '@/common/components/select';
import { useTopNavDate } from '@/common/hooks/storage';
import { useSchedules } from '@/common/hooks/use-schedules';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { useTimeBlock } from '@/feat/reservation/context';
import { css } from '@emotion/react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { alpha, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { sortBy } from 'lodash-es';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo } from 'react';
import styles from './index.module.scss';

const TimelineListViewScheduleSelector = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [topNavDate] = useTopNavDate();
  const { timeBlock, setTimeBlock } = useTimeBlock();
  const { to12HourBase } = useI18nTimeUtils();
  const { data: dataSchedules, isLoading } = useSchedules({
    params: { date: format(topNavDate, 'yyyy-MM-dd') },
    config: {},
  });

  const workingTimeBlocks = useMemo(() => {
    if (!dataSchedules || !dataSchedules.length) return [];

    return sortBy(
      dataSchedules.filter((item) => item?.ticket?.isActive),
      ['dailyStartTime']
    ).reduce((acc, it, idx) => {
      const { dailyStartTime, dailyEndTime } = it;
      if (!dailyEndTime || !dailyStartTime) {
        return acc;
      }
      if (idx === 0) {
        // if first schedule, insert to the array
        acc.push({
          start: dailyStartTime,
          end: dailyEndTime,
        });
      } else {
        const lastBlock = acc[acc.length - 1];
        if (lastBlock) {
          const isOverlap =
            lastBlock.start < dailyEndTime && lastBlock.end > dailyStartTime;
          if (isOverlap) {
            lastBlock.end =
              dailyEndTime > lastBlock.end ? dailyEndTime : lastBlock.end;
          } else {
            // if not overlap, insert to the array
            acc.push({
              start: dailyStartTime,
              end: dailyEndTime,
            });
          }
        }
      }
      return acc;
    }, [] as { start: string; end: string }[]);
  }, [dataSchedules]);

  const handleChange = (val: string) => {
    if (val === 'All Day') {
      setTimeBlock({ start: '', end: '' });
    } else {
      const findTimeBlock = workingTimeBlocks.find(
        (item) => item.start === val
      );
      if (findTimeBlock) {
        setTimeBlock(findTimeBlock);
      }
    }
  };

  const options = useMemo(() => {
    return [
      { value: 'All Day', label: t('All Day') },
      ...workingTimeBlocks.map((item) => ({
        value: item.start,
        label: [to12HourBase(item.start), to12HourBase(item.end)].join(' - '),
      })),
    ];
  }, [workingTimeBlocks, t, to12HourBase]);

  useEffect(() => {
    if (!timeBlock.start || isLoading) {
      return;
    }
    const isTimeBlockExisted = workingTimeBlocks.some(
      (block) => block.start === timeBlock.start && block.end === timeBlock.end
    );
    if (!isTimeBlockExisted) {
      setTimeBlock({ start: '', end: '' });
    }
  }, [
    workingTimeBlocks,
    setTimeBlock,
    timeBlock.start,
    timeBlock.end,
    isLoading,
  ]);

  return (
    <Select
      disabled={isLoading}
      className={styles['schedule-selector']}
      value={timeBlock.start || 'All Day'}
      onChange={handleChange}
      IconComponent={KeyboardArrowDownIcon}
      MenuProps={{ classes: { list: styles.dropdown } }}
      options={options}
      fullWidth={false}
      css={css`
        .MuiNativeSelect-select,
        .MuiSelect-select {
          padding: 8px 16px;
          padding-right: calc(16px + (24px)) !important;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          height: 100%;
        }
        & > div[aria-expanded='false'] {
          background-color: ${theme.palette.grey[200]};
        }
        & > div[aria-expanded='true'] {
          background-color: ${alpha(theme.palette.primary.main, 0.08)};
        }
        svg.MuiSelect-icon {
          width: 24px;
          height: 24px;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
        }
      `}
    />
  );
};

export default TimelineListViewScheduleSelector;
