import { getUnixTimeMs } from '@/common/lib/date-time';
import { add, endOfDay, format, parse, startOfDay } from 'date-fns';
import { orderBy } from 'lodash-es';
import {
  DEFAULT_END_PADDING,
  DEFAULT_START_PADDING,
  VISIBLE_HOURS,
} from './constants';
import { TimelineGroup, TimelineGroupSortingOption } from './types';

export const getGanttViewTodayVisibleStart = () => {
  const now = new Date();
  const startOfDayMs = getUnixTimeMs(startOfDay(now));
  const endOfDayMs = getUnixTimeMs(endOfDay(now));
  const maxStartMs = endOfDayMs - VISIBLE_HOURS;
  const startPadding = getUnixTimeMs(now) - DEFAULT_START_PADDING;

  return Math.max(startOfDayMs, Math.min(maxStartMs, startPadding));
};

export const getGanttViewTodayVisibleEnd = () => {
  const now = new Date();
  const startOfDayMs = getUnixTimeMs(startOfDay(now));
  const endOfDayMs = getUnixTimeMs(endOfDay(now));
  const minEndTime = startOfDayMs + VISIBLE_HOURS;
  const endPadding = getUnixTimeMs(now) + DEFAULT_END_PADDING;

  return Math.max(minEndTime, Math.min(endOfDayMs, endPadding));
};

export interface OptionType {
  label: string;
  value: string;
}

export const generateBlockingTimeOptions = (
  from: string,
  to: string
): OptionType[] => {
  const options: OptionType[] = [];
  const endTime = parse(to, 'HH:mm:ss', new Date());
  let cursor = parse(from, 'HH:mm:ss', new Date());
  while (cursor <= endTime) {
    const value = format(cursor, 'HH:mm:ss');
    const label = format(cursor, 'HH:mm');
    options.push({ value, label });
    cursor = add(cursor, { minutes: 15 });
  }
  return options;
};

export const sortTimelineGroupItems = (
  groups: TimelineGroup[],
  sortingOption: TimelineGroupSortingOption
) => {
  const areaGroups = groups.filter((it) => it.type === 'area');
  const tableGroups = groups.filter((it) => it.type === 'table');

  const tableGroupsByArea = tableGroups.reduce((acc, it) => {
    if (!it.parent) {
      return acc;
    }
    if (!acc[it.parent]) {
      acc[it.parent] = [];
    }
    acc[it.parent]?.push(it);

    return acc;
  }, {} as Record<string, TimelineGroup[]>);

  const output: TimelineGroup[] = [];

  areaGroups.forEach((area) => {
    output.push(area);
    const tableGroups = tableGroupsByArea[area.id];
    if (tableGroups) {
      output.push(
        ...orderBy(tableGroups, (table) => {
          switch (sortingOption) {
            case TimelineGroupSortingOption.ALPHABETICAL:
              return table.title;
            case TimelineGroupSortingOption.PRIORITY:
            default:
              return table.priority;
          }
        })
      );
    }
  });

  return output;
};
