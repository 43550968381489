import { ButtonProps, IconButton, Tooltip, useTheme } from '@mui/material';
import { css } from '@emotion/react';
import React from 'react';

interface TopBarBtnProps extends ButtonProps {
  toolTipTitle?: string;
  active?: boolean;
}

export const TopBarBtn = React.forwardRef<HTMLButtonElement, TopBarBtnProps>(
  function TopBarBtnWithRef({ children, ...props }, ref) {
    const theme = useTheme();
    const { toolTipTitle, active = false, ...otherProps } = props;

    const btnEl = (
      <IconButton
        ref={ref}
        css={css`
          color: ${active
            ? theme.palette.primary.main
            : theme.palette.grey[600]};
        `}
        {...otherProps}
      >
        {children}
      </IconButton>
    );

    if (!toolTipTitle) {
      return btnEl;
    }

    return (
      <Tooltip
        componentsProps={{
          tooltip: {
            style: {
              marginBottom: 0,
            },
          },
        }}
        title={toolTipTitle}
        placement="top"
        arrow
      >
        {btnEl}
      </Tooltip>
    );
  }
);
