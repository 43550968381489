import { getUnixTimeMs } from '@/common/lib/date-time';
import { Area } from '@/common/types/area';
import { ReservationItem } from '@/common/types/reservation';
import { AddWalkInPayload, UpdateReservationPayload } from '../../types';
import {
  getReservationSeatedDepartedTime,
  getReservationStartEndTime,
} from '../../utils';
import { TimelineGroup, TimelineItem, TimelineItemType } from './types';

export const buildTimelineGroupsFromAreas = (
  areas: Area[]
): TimelineGroup[] => {
  return areas.reduce((acc, area) => {
    acc.push({
      id: area.id || area.areaId || '',
      title: `${area.displayName || 'None'} ${
        area.name ? `(${area.name})` : ''
      }`,
      type: 'area',
      priority: area.order,
    });
    if (area.tables && area.tables.length) {
      area.tables.forEach((t) => {
        acc.push({
          id: t.id,
          title: t.name,
          parent: area.id || area.areaId,
          type: 'table',
          minPax: t.minPax,
          maxPax: t.maxPax,
          priority: t.priority,
        });
      });
    }
    return acc;
  }, [] as TimelineGroup[]);
};

export const buildTimelineItemsFromReservations = (
  items: ReservationItem[]
): TimelineItem[] => {
  return items.reduce((acc, r) => {
    const { seated, departed } = getReservationSeatedDepartedTime(r);
    const { start, end } = getReservationStartEndTime(r);
    const rBegin = seated || start;
    const rEnd = departed || end;
    const startTime = getUnixTimeMs(rBegin);
    const endTime = getUnixTimeMs(rEnd);

    if (!r.tables || !r.tables.length) {
      return acc;
    }
    r.tables.forEach((t) => {
      acc.push({
        type: TimelineItemType.RESERVATION,
        id: r.id + '_' + t.id,
        group: t.id,
        span: 1,
        start_time: startTime,
        end_time: endTime,
        reservationId: r.id,
        tableId: t.id,
      });
    });

    return acc;
  }, [] as TimelineItem[]);
};

export const getUpdateReservationPayload = (
  r: ReservationItem
): UpdateReservationPayload => {
  return {
    id: r.id,
    tableIds: r.tables?.map((it) => it.id) ?? [],
    diningInterval: r.diningInterval || 0,
    customerId: r.user?.mcaId || r.customerId || '',
    reservationDate: r.reservationDate,
    reservationTime: r.reservationTime,
    numberOfAdults: r.numberOfAdults,
    numberOfChildren: r.numberOfChildren,
    email: r.user?.email || '',
    salutation: r.user?.salutation || '',
    notes: r.notes || '',
    notesDiner: r.notesDiner || '',
    staffNotes: r.staffNotes || '',
    occasions: r.occasions || '',
    allowSmsNotify: !!r.allowSmsNotify,
    allowEmailNotify: !!r.allowEmailNotify,
    allowAutomatedCallNotify: !!r.allowAutomatedCallNotify,
    firstName: r.customerFirstName || '',
    lastName: r.customerLastName || '',
    phone: r.customerPhone || '',
    ticketId: r.ticket?.id || '',
  };
};

export const getUpdateWalkInPayload = (
  r: ReservationItem
): AddWalkInPayload & {
  id: string;
} => {
  return {
    id: r.id,
    tableIds: r.tables?.map((it) => it.id) ?? [],
    diningInterval: r.diningInterval || 0,
    customerId: r.user?.mcaId || r.customerId || '',
    numberOfAdults: r.numberOfAdults,
    numberOfChildren: r.numberOfChildren,
    email: r.user?.email || '',
    language: r?.user?.language || '',
    salutation: r.user?.salutation || '',
    notes: r.notes || '',
    notesDiner: r.notesDiner || '',
    staffNotes: r.staffNotes || '',
    occasions: r.occasions || '',
    canSendEmailMarketing: r?.user?.canSendEmailMarketing || false,
    customerFirstName: r.customerFirstName || '',
    customerLastName: r.customerLastName || '',
    customerPhone: r.customerPhone || '',
  };
};
