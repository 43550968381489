import { getUnixTimeMs } from '@/common/lib/date-time';
import { Area, AreaTable } from '@/common/types/area';
import { ReservationItem } from '@/common/types/reservation';
import { RESERVATION_STATUS } from '@/common/types/reservation-status-flow';
import { Ticket } from '@/common/types/ticket';
import { OnItemDragObjectMove } from '@oddle.me/react-calendar-timeline';
import { fromUnixTime, isToday, startOfDay } from 'date-fns';
import { atom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import { nanoid } from 'nanoid';
import { getReservationStartEndTime } from '../../utils';
import { LINE_HEIGHT, SELECTED_RESERVATION_HEIGHT } from './constants';
import {
  buildTimelineGroupsFromAreas,
  buildTimelineItemsFromReservations,
} from './parser';
import {
  OverlapBlockOutProblemDetector,
  OverlapReservationTableProblemDetector,
  ReservationProblemProcessor,
  TimeChangeProblemDetector,
} from './reservation-changes';
import {
  DisableOverlayItem,
  Filter,
  ReservationChange,
  ReservationChangeProblem,
  ReservationChangeType,
  SelectedItem,
  SelectedItemType,
  TimelineGroup,
  TimelineGroupSortingOption,
  TimelineItem,
  TimelineItemType,
} from './types';
import {
  getGanttViewTodayVisibleEnd,
  getGanttViewTodayVisibleStart,
  sortTimelineGroupItems,
} from './utils';

export const selectedItemAtom = atom<SelectedItem>(null);

export const visibleTimeAtom = atom<{
  start: number;
  end: number;
}>({
  start: getGanttViewTodayVisibleStart(),
  end: getGanttViewTodayVisibleEnd(),
});

// For showing timeline bar. Should be update every seconds
export const currentTimeAtom = atom<number>(getUnixTimeMs(new Date()));

export const selectedDateAtom = atom<Date, [Date], void>(
  new Date(),
  (get, set, newVal) => {
    set(selectedDateAtom, newVal);
    let start = 0;
    let end = 0;
    if (isToday(newVal)) {
      start = getGanttViewTodayVisibleStart();
      end = getGanttViewTodayVisibleEnd();
    } else {
      const visibleTime = get(visibleTimeAtom);

      const startOfDayNow = startOfDay(fromUnixTime(visibleTime.start / 1000));
      const startOfNextVal = startOfDay(newVal);

      start =
        getUnixTimeMs(startOfNextVal) +
        (visibleTime.start - getUnixTimeMs(startOfDayNow));
      end = start + (visibleTime.end - visibleTime.start);
    }
    set(visibleTimeAtom, { start, end });
  }
);

// Filter
export const ticketsAtom = atom<Ticket[]>([]);

export const activeTicketsAtom = selectAtom(ticketsAtom, (value) =>
  value.filter((it) => it.isActive)
);

export const filterAtom = atom<Filter>({
  pax: null,
  reservationStatus: RESERVATION_STATUS.filter(
    (status) =>
      status !== 'R::NO_SHOW' &&
      status !== 'R::CANCELLED' &&
      status !== 'R::EXPIRED'
  ),
});

// List of areas & tables
export const areasAtom = atom<Area[]>([]);

export const tablesAtom = atom<AreaTable[]>((get) => {
  const areas = get(areasAtom);
  return flatten(areas.map((it) => it.tables || []));
});

export const areaByIdAtom = selectAtom(areasAtom, (areas) =>
  keyBy(areas, 'areaId')
);

export const tableByIdAtom = selectAtom(
  areasAtom,
  (areas) =>
    keyBy(
      flatten(areas.map((a) => a.tables)).reduce((acc, t) => {
        if (t) {
          acc.push(t);
        }
        return acc;
      }, [] as AreaTable[]),
      'id'
    ) as Record<string, AreaTable>
);

export const areaByTableIdAtom = selectAtom(areasAtom, (areas) =>
  areas.reduce((acc, it) => {
    (it.tables || []).forEach((t) => {
      if (t.id && it.areaId) {
        acc[t.id] = it.areaId;
      }
    });
    return acc;
  }, {} as Record<string, string>)
);

// For showing areas & tables
export const timelineGroupsAtom = atom<TimelineGroup[]>((get) =>
  buildTimelineGroupsFromAreas(get(areasAtom))
);

// All reservations that existed
export const reservationItemsAtom = atom<ReservationItem[]>([]);

// A map id -> reservation. For quicker query reservation item
export const reservationItemByIdAtom = selectAtom(
  reservationItemsAtom,
  (reservationItems) => keyBy(reservationItems, 'id')
);

// All checkboxes for multi-table reservation
export const multiTableCheckboxItemsAtom = atom<TimelineItem[]>([]);

export const multiTableCheckboxItemByIdAtom = selectAtom(
  multiTableCheckboxItemsAtom,
  (multiTableCheckboxItems) => keyBy(multiTableCheckboxItems, 'id')
);

export const visibleTimelineItemsAtom = atom<ReservationItem[]>((get) => {
  const reservationItems = get(reservationItemsAtom);
  const filterValue = get(filterAtom);
  const updatingReservation = get(updatingReservationAtom);

  return reservationItems
    .map((it) => (it.id === updatingReservation?.id ? updatingReservation : it))
    .filter((it) => {
      const statusMatched =
        filterValue.reservationStatus &&
        filterValue.reservationStatus.length &&
        filterValue.reservationStatus.includes(it.rStatus);

      const ticketMatched =
        !filterValue.reservationTicket ||
        it.ticket?.id === filterValue.reservationTicket.id;

      const paxMatched =
        !filterValue.pax ||
        (Array.isArray(filterValue.pax) &&
          filterValue.pax.includes(it.pax as number));
      let searchTextMatched = true;
      if (filterValue.reservationSearchValue) {
        const { user } = it || {};
        const { firstName, lastName, phone, email } = user || {};
        const listValueFilterBy = [firstName, lastName, phone, email].map(
          (item) => item?.toLowerCase()
        );

        searchTextMatched = listValueFilterBy.some((item) =>
          String(item).includes(
            String(filterValue.reservationSearchValue)?.trim().toLowerCase()
          )
        );
      }

      return statusMatched && ticketMatched && paxMatched && searchTextMatched;
    });
});

// All visible items that shown in the view. The filter logic should be here.
export const timelineItemsAtom = atom<TimelineItem[]>((get) => {
  const selectedItem = get(selectedItemAtom);

  const visibleReservationItems = get(visibleTimelineItemsAtom);

  const timelineItems = buildTimelineItemsFromReservations(
    visibleReservationItems
  );
  if (
    selectedItem?.type === SelectedItemType.TIMELINE_ITEM &&
    selectedItem.item.type === TimelineItemType.PLACEHOLDER
  ) {
    timelineItems.push(selectedItem.item);
  }

  return orderBy(timelineItems, [(item) => item.start_time], ['asc']);
});

// Map id -> timeline item for quicker access
export const timelineItemsByIdAtom = atom<Record<string, TimelineItem>>(
  (get) => {
    const timelineItems = get(timelineItemsAtom);
    return keyBy(timelineItems, 'id');
  }
);

// Collapsed state of areas: areaId -> isCollapsed
export const collapsedAreaIdsAtom = atom<Record<string, boolean>>({});

export const timelineGroupSortingAtom = atom<TimelineGroupSortingOption>(
  TimelineGroupSortingOption.PRIORITY
);

// All visible groups that shown in the view. The filter logic should be here
export const visibleTimelineGroupAtom = atom<TimelineGroup[]>((get) => {
  const collapsedIds = get(collapsedAreaIdsAtom);
  const selectedItem = get(selectedItemAtom);
  const timelineGroupSorting = get(timelineGroupSortingAtom);
  const groups = sortTimelineGroupItems(
    get(timelineGroupsAtom).filter((group) => {
      if (group.type === 'area') {
        return true;
      }
      return !collapsedIds[group.parent || ''];
    }),
    timelineGroupSorting
  );
  if (
    selectedItem?.type === SelectedItemType.TIMELINE_ITEM &&
    selectedItem.item.type === TimelineItemType.RESERVATION
  ) {
    const groupIdx = groups.findIndex((g) => g.id === selectedItem.item.group);
    const noDumpRows =
      (selectedItem.size?.height
        ? Math.ceil(selectedItem.size.height / LINE_HEIGHT)
        : SELECTED_RESERVATION_HEIGHT) -
      (groups.length - groupIdx);

    if (noDumpRows > 0) {
      return groups.concat(
        Array(noDumpRows)
          .fill(null)
          .map(() => ({ id: nanoid(), type: 'dump-table', title: '' }))
      );
    }
  }
  return groups;
});

export const selectedReservationAtom = atom<ReservationItem | null>((get) => {
  const selectedItem = get(selectedItemAtom);
  if (
    !selectedItem ||
    selectedItem.type !== SelectedItemType.TIMELINE_ITEM ||
    selectedItem.item.type !== TimelineItemType.RESERVATION
  ) {
    return null;
  }
  const reservationByIdMaps = get(reservationItemByIdAtom);
  return reservationByIdMaps[selectedItem.item.reservationId] || null;
});

// Initial reservation state before any updates happen. This is for reverting if any updates failed
export const updatingReservationAtom = atom<ReservationItem | null>(null);

export const reservationProblemsProcessorAtom =
  atom<ReservationProblemProcessor>((get) => {
    const areaById = get(areaByIdAtom);
    const areaByTableId = get(areaByTableIdAtom);
    const tableById = get(tableByIdAtom);
    const reservationItems = get(reservationItemsAtom);

    return new ReservationProblemProcessor()
      .addProblemDetector(
        new OverlapBlockOutProblemDetector(areaById, areaByTableId, tableById)
      )
      .addProblemDetector(new TimeChangeProblemDetector())
      .addProblemDetector(
        new OverlapReservationTableProblemDetector(reservationItems)
      );
  });

// Disabled Item
export const disabledOverlayItemsAtom = atom<DisableOverlayItem[]>([]);

// For storing initialScrollTop
export const scrollTopAtom = atom<number>(0);

// Scroll El Ref
export const outerScrollElAtom = atom<HTMLDivElement | null>(null);

// View modes
export const viewModeAtom = atom<{ isMultiTableAssignment: boolean }>({
  isMultiTableAssignment: false,
});

// Blocking areas
export const blockingAreaIdAtom = atom<string | number | null>(null);

// Blocking table
export const blockingTableIdAtom = atom<string | number | null>(null);
export const unblockingTableIdAtom = atom<string | number | null>(null);

// updating reservation changes
export const reservationChangesAtom = atom<ReservationChange[]>((get) => {
  const updating = get(updatingReservationAtom);
  if (!updating) {
    return [];
  }
  const reservationItemsById = get(reservationItemByIdAtom);
  const current = reservationItemsById[updating.id];
  if (!current) {
    return [];
  }
  const changes: ReservationChange[] = [];
  if (current.reservationTime !== updating.reservationTime) {
    changes.push({
      type: ReservationChangeType.RESERVATION_TIME,
      oldValue: current.reservationTime,
      newValue: updating.reservationTime,
    });
  }
  if (
    current.tables?.map((t) => t.id).join(',') !==
    updating.tables?.map((t) => t.id).join(',')
  ) {
    changes.push({
      type: ReservationChangeType.TABLE,
      oldValue: current.tables,
      newValue: updating.tables,
    });
  }
  if (current.diningInterval !== updating.diningInterval) {
    changes.push({
      type: ReservationChangeType.DINING_INTERVAL,
      oldValue: current.diningInterval,
      newValue: updating.diningInterval,
    });
  }
  return changes;
});

export const reservationChangeProblemsAtom = atom<ReservationChangeProblem[]>(
  (get) => {
    const problemsProcessor = get(reservationProblemsProcessorAtom);
    const updating = get(updatingReservationAtom);
    if (!updating) return [];
    const reservationById = get(reservationItemByIdAtom);
    const current = reservationById[updating.id];
    if (!current) return [];
    return problemsProcessor
      .setReservationItem(current)
      .detectProblems(updating);
  }
);

export const itemDraggingAtom = atom<{
  isDragging: boolean;
  obj: OnItemDragObjectMove | null;
}>({
  isDragging: false,
  obj: null,
});

export const reservationClashedIdsAtom = atom<string[]>((get) => {
  const reservationItems = get(reservationItemsAtom);
  const updating = get(updatingReservationAtom);
  if (!updating) return [];

  const problemsProcessor = new OverlapReservationTableProblemDetector(
    reservationItems
  ).getProblem(updating, updating);

  return problemsProcessor?.reservationIds || [];
});

export const reservationStatByHourAtom = atom<
  Record<string, { reservationCount: number; paxCount: number }>
>((get) => {
  const visibleReservationItems = get(visibleTimelineItemsAtom);

  // using reduce to calculate the total pax and reservation count, group by every hour
  return visibleReservationItems.reduce((acc, item) => {
    const { numberOfAdults = 0, numberOfChildren = 0 } = item;
    const { start, end } = getReservationStartEndTime(item);

    // Get the start and end hours
    const startHour = start.getHours();
    const endHour = end.getHours();

    // loop through each hour between start and end
    for (let i = startHour; i <= endHour; i++) {
      // only include the end hour if the reservation goes past the start of the end hour
      if (i < endHour || (i === endHour && end.getMinutes() > 0)) {
        const hour = i.toString();
        acc[hour] = acc[hour] || { reservationCount: 0, paxCount: 0 };
        const it = acc[hour];
        if (it) {
          it.reservationCount += 1;
          it.paxCount += numberOfAdults + numberOfChildren;
        }
      }
    }

    return acc;
  }, {} as Record<string, { reservationCount: number; paxCount: number }>);
});
