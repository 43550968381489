import React from 'react';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Theme } from '@mui/system';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import NextLink from '@/common/components/next-link';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styled } from '@mui/material/styles';
import { ServiceTiming } from '@/common/types/schedule';
import LoadingScreen from '@/common/components/loading-screen/LoadingScreen';
import parse from 'html-react-parser';
import { Summary } from '@/common/types/monthly-reservation-summary';
import { ticketColor } from '@/common/types/ticket.base';
import useI18nTimeUtils from '@/common/i18n-time-utils';

const StyledLoadingBox = styled(Box)({
  width: '100%',
  height: '100%',
  backgroundColor: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

interface EventManagementProps {
  sx?: SxProps<Theme>;

  date: Date;

  event: Summary | undefined;

  loading?: boolean;
}

const EventManagement: React.FC<EventManagementProps> = ({
  sx,
  date,
  event,
  loading,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { formatDate, to12HourBase } = useI18nTimeUtils();

  const renderServiceRunning = (serviceTiming: ServiceTiming) => (
    <Box
      key={serviceTiming.id}
      sx={{
        padding: '12px 0',
        borderBottom: `1px solid ${theme.palette.grey[500_24]}`,

        '&:last-of-type': {
          paddingBottom: 0,
          borderBottom: 'none',
        },
      }}
    >
      {serviceTiming.startTime && serviceTiming.endTime && (
        <Typography
          variant="caption"
          sx={{
            color: theme.palette.text.secondary,
            marginBottom: '4px',
          }}
        >
          {to12HourBase(serviceTiming.startTime)} -{' '}
          {to12HourBase(serviceTiming.endTime)}
        </Typography>
      )}

      <Box sx={{ display: 'flex', gap: '8px' }}>
        <Box
          sx={{
            width: '11px',
            height: '11px',
            flex: '0 0 auto',
            marginTop: '7px',
            borderRadius: '50%',
            backgroundColor: ticketColor(serviceTiming.ticket?.color).swatch,
          }}
        />
        <Typography variant="body2">{serviceTiming.ticket?.name}</Typography>
      </Box>
    </Box>
  );

  const renderServiceBlocked = (serviceTiming: ServiceTiming) => (
    <Box
      key={serviceTiming.id}
      sx={{
        padding: '12px 0',
        borderBottom: `1px solid ${theme.palette.grey[500_24]}`,

        '&:last-of-type': {
          paddingBottom: 0,
          borderBottom: 'none',
        },
      }}
    >
      {serviceTiming.startTime && serviceTiming.endTime && (
        <Typography
          variant="caption"
          sx={{
            color: theme.palette.text.disabled,
            marginBottom: '4px',
          }}
        >
          {to12HourBase(serviceTiming.startTime)} -{' '}
          {to12HourBase(serviceTiming.endTime)}
        </Typography>
      )}
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <Box
          sx={{
            width: '11px',
            height: '11px',
            flex: '0 0 auto',
            marginTop: '7px',
            borderRadius: '50%',
            backgroundColor: theme.palette.grey[500_24],
          }}
        />
        <Typography
          variant="body2"
          sx={{
            textDecoration: 'line-through',
            color: theme.palette.text.disabled,
          }}
        >
          {serviceTiming.ticket?.name}
        </Typography>
      </Box>
    </Box>
  );

  const renderNormalServices = (serviceTimingsRunning: ServiceTiming[]) => {
    if (!serviceTimingsRunning || serviceTimingsRunning.length === 0) {
      return (
        <Typography variant="body2">
          <Trans>No online services set up.</Trans>
        </Typography>
      );
    } else {
      return (
        <Box sx={{ fontSize: '14px' }}>
          <Typography variant="body2" sx={{ marginBottom: '4px' }}>
            <Trans>
              {{ serviceTimingsRunning: serviceTimingsRunning.length }} online
              services running.
            </Trans>
          </Typography>
          {serviceTimingsRunning.map(renderServiceRunning)}
        </Box>
      );
    }
  };

  const renderSpecialServices = (serviceTimingsRunning: ServiceTiming[]) => {
    const specialServices = serviceTimingsRunning.filter(
      (service) => !service.isRecurring
    );
    return (
      <Box sx={{ fontSize: '14px' }}>
        <Typography variant="body2" sx={{ marginBottom: '4px' }}>
          <Trans>
            {{ specialServices: specialServices?.length }} special online
            services running.
          </Trans>
        </Typography>
        {specialServices.map(renderServiceRunning)}
      </Box>
    );
  };

  const renderBlockedServices = (
    serviceTimingsRunning: ServiceTiming[],
    serviceTimingsBlocked: ServiceTiming[],
    reason: string | undefined
  ) => {
    const countRunningServices = serviceTimingsRunning?.length || 0;
    const countAllServices =
      serviceTimingsRunning?.length + serviceTimingsBlocked?.length || 0;
    return (
      <Box sx={{ fontSize: '14px' }}>
        {countRunningServices !== 0 ? (
          <Typography variant="body2" sx={{ marginBottom: '4px' }}>
            {countRunningServices}/{countAllServices}{' '}
            {reason ? (
              <Trans>
                online services running due to {{ reason: parse(reason) }}.
              </Trans>
            ) : (
              <Trans>
                online services running based on your partially blocked
                schedule.
              </Trans>
            )}
          </Typography>
        ) : (
          <Typography variant="body2" sx={{ marginBottom: '4px' }}>
            {countAllServices}/{countAllServices}{' '}
            {reason ? (
              <Trans>
                online services blocked due to {{ reason: parse(reason) }}.
              </Trans>
            ) : (
              <Trans>online services blocked.</Trans>
            )}
          </Typography>
        )}
        {serviceTimingsRunning.map(renderServiceRunning)}
        {serviceTimingsBlocked.map(renderServiceBlocked)}
      </Box>
    );
  };

  const renderServices = (event: Summary) => {
    const {
      isSpecial,
      isBlockOutDate,
      serviceTimingsBlocked,
      serviceTimingsRunning,
      reason,
    } = event;
    // block out has higher priority
    if (isBlockOutDate) {
      return renderBlockedServices(
        serviceTimingsRunning || [],
        serviceTimingsBlocked || [],
        reason
      );
    }
    if (isSpecial) {
      return renderSpecialServices(serviceTimingsRunning || []);
    }
    return renderNormalServices(serviceTimingsRunning || []);
  };

  return (
    <Box
      sx={{
        ...sx,
        width: '236px',
        flex: '0 0 auto',
        display: 'flex',
        flexFlow: 'column',
        background: '#fff',
        boxShadow:
          '0px 0px 2px rgba(145, 158, 171, 0.24), 0px 16px 32px -4px rgba(145, 158, 171, 0.24)',
        borderRadius: '16px',
      }}
    >
      <Box
        sx={{
          padding: '8px 16px',
          borderBottom: `1px solid ${theme.palette.grey[500_24]}`,
          height: '40px',
        }}
      >
        <Typography variant="subtitle1">
          {`${formatDate(date, { en: 'EEE' })}, ${formatDate(date, {
            en: 'd MMM yyyy',
            zh: 'yyyy MMM do',
          })}`}
        </Typography>
      </Box>
      <Box
        sx={{
          padding: '12px 16px',
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        {loading ? (
          <StyledLoadingBox>
            <LoadingScreen />
          </StyledLoadingBox>
        ) : (
          event && renderServices(event)
        )}
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          padding: '8px 16px',
          borderTop: `1px solid ${theme.palette.grey[500_24]}`,
        }}
      >
        <Button
          component={NextLink}
          href="/settings/schedules/weekly"
          variant="text"
          endIcon={<ArrowForwardIcon />}
        >
          {t('Go to Schedule Settings')}
        </Button>
      </Box>
    </Box>
  );
};

export default EventManagement;
