import { getBlockStartEndTime } from '@/common/components/table-block';
import { Area, AreaTable } from '@/common/types/area';
import { ReservationItem } from '@/common/types/reservation';
import { intersection } from 'lodash-es';
import first from 'lodash-es/first';
import {
  ReservationChangeProblem,
  ReservationChangeProblemLevel,
  ReservationChangeProblemType,
} from './types';
import { getReservationStartEndTime } from '../../utils';

export class ReservationProblemProcessor {
  private problemDetectors: ReservationProblemDetector[] = [];
  private reservationItem: ReservationItem | null = null;

  setReservationItem(item: ReservationItem | null) {
    this.reservationItem = item;
    return this;
  }

  addProblemDetector(detector: ReservationProblemDetector) {
    this.problemDetectors.push(detector);
    return this;
  }

  detectProblems(next: ReservationItem) {
    const problems: ReservationChangeProblem[] = [];
    this.problemDetectors.forEach((detector) => {
      if (!this.reservationItem) return;
      const problem = detector.getProblem(this.reservationItem, next);
      if (problem) {
        problems.push(problem);
      }
    });
    return problems;
  }
}

/*****************************
 * PROBLEMS DETECTORS
 ****************************/
interface ReservationProblemDetector {
  getProblem(
    current: ReservationItem,
    next: ReservationItem
  ):
    | (ReservationChangeProblem & {
        reservationIds?: string[];
      })
    | null;
}

export class OverlapBlockOutProblemDetector
  implements ReservationProblemDetector
{
  constructor(
    private areaById: Record<string, Area>,
    private areaByTableId: Record<string, string>,
    private tableById: Record<string, AreaTable>
  ) {}

  isWithinBlockOutSection(r: ReservationItem) {
    const tableIds = r.tables?.map((it) => it.id) || [];

    const needCheckAreas = tableIds
      ? tableIds.reduce((acc, id) => {
          const areaId = this.areaByTableId[id];
          if (areaId) {
            const area = this.areaById[areaId];
            if (area) {
              acc.push(area);
            }
          }
          return acc;
        }, [] as Area[])
      : [];

    const { start: itemStart, end: itemEnd } = getReservationStartEndTime(r);
    const isBlockedOutByArea = needCheckAreas.some((area) => {
      if (!area.areaBlocks || !area.areaBlocks.length) {
        return false;
      }
      return area.areaBlocks
        .filter((b) => b.date === r.reservationDate)
        .some((b) => {
          if (b.entireDay) {
            return true;
          }
          const { start: bStart, end: bEnd } = getBlockStartEndTime(b);
          return itemStart < bEnd && itemEnd > bStart;
        });
    });

    const isBlockedOutByTables = r.tables
      ? r.tables.some((t) => {
          const table = this.tableById[t.id];
          if (!table?.enabled) {
            return true;
          }
          if (!table || !table.tableBlocks || !table.tableBlocks.length) {
            return false;
          }
          return table.tableBlocks
            .filter((b) => b.date === r.reservationDate)
            .some((b) => {
              if (b.entireDay) {
                return true;
              }
              const { start: bStart, end: bEnd } = getBlockStartEndTime(b);
              return itemStart < bEnd && itemEnd > bStart;
            });
        })
      : false;
    return isBlockedOutByArea || isBlockedOutByTables;
  }

  getProblem(
    _: ReservationItem,
    next: ReservationItem
  ): ReservationChangeProblem | null {
    return this.isWithinBlockOutSection(next)
      ? {
          type: ReservationChangeProblemType.OVERLAP_BLOCK_OUT,
          level: ReservationChangeProblemLevel.WARNING,
        }
      : null;
  }
}

export class DisabledTableProblemDetector
  implements ReservationProblemDetector
{
  constructor(private tableById: Record<string, AreaTable>) {}
  getProblem(
    _: ReservationItem,
    next: ReservationItem
  ): ReservationChangeProblem | null {
    const problems = next.tables?.reduce((acc, t) => {
      const table = this.tableById[t.id];
      if (table && !table.enabled) {
        acc.push({
          type: ReservationChangeProblemType.OVERLAP_BLOCK_OUT,
          level: ReservationChangeProblemLevel.WARNING,
        });
      }
      return acc;
    }, [] as ReservationChangeProblem[]);

    return first(problems) || null;
  }
}

export class TimeChangeProblemDetector implements ReservationProblemDetector {
  getProblem(
    current: ReservationItem,
    next: ReservationItem
  ): ReservationChangeProblem | null {
    return current.reservationTime !== next.reservationTime ||
      current.diningInterval !== next.diningInterval
      ? {
          level: ReservationChangeProblemLevel.INFO,
          type: ReservationChangeProblemType.UPDATE_TIME,
        }
      : null;
  }
}

export class OverlapReservationTableProblemDetector
  implements ReservationProblemDetector
{
  constructor(private reservationItems: ReservationItem[]) {}
  getProblem(
    _: ReservationItem,
    next: ReservationItem
  ):
    | (ReservationChangeProblem & {
        reservationIds?: string[];
      })
    | null {
    const tableIds = next.tables?.map((t) => t.id) || [];
    const { start, end } = getReservationStartEndTime(next);
    const listOverlapReservation = this.reservationItems.filter((r) => {
      const isCancelled =
        r.cancelledByRestaurant === true || r.cancelledByRestaurant === null;
      if (r.id === next.id || isCancelled) return false;
      const rTableIds = r.tables?.map((t) => t.id) || [];
      const isSameTable = intersection(rTableIds, tableIds).length > 0;
      if (!isSameTable) return false;
      const { start: rStart, end: rEnd } = getReservationStartEndTime(r);
      return rEnd > start && rStart < end;
    });
    return listOverlapReservation.length > 0
      ? {
          level: ReservationChangeProblemLevel.WARNING,
          type: ReservationChangeProblemType.OVERLAP_RESERVATION_TABLE,
          reservationIds: listOverlapReservation.map((r) => r.id),
        }
      : null;
  }
}
