import { AreaTable } from '@/common/types/area';
import { Ticket } from '@/common/types/ticket';
import {
  TimelineGroup as CalendarTimelineGroup,
  TimelineItem as CalendarTimelineItem,
} from '@oddle.me/react-calendar-timeline';

export type TimelineGroup = CalendarTimelineGroup<{
  type: 'table' | 'area' | 'dump-table';
  parent?: string;
  minPax?: number;
  maxPax?: number;
  priority?: number | null;
}>;

export enum TimelineItemType {
  RESERVATION = 'reservation',
  PLACEHOLDER = 'placeholder',
  CHECKBOX = 'checkbox',
}

interface TimelineItemBase {
  id: string;
  type: TimelineItemType;
  tableId: string;
  span: number;
}

export interface TimelineReservationItem extends TimelineItemBase {
  type: TimelineItemType.RESERVATION;
  reservationId: string;
  tableId: string;
}

export enum PlaceholderType {
  WALK_IN = 'walk_in',
  RESERVATION = 'reservation',
}
export interface TimelinePlaceholderItem extends TimelineItemBase {
  type: TimelineItemType.PLACEHOLDER;
  placeholderType: PlaceholderType;
}

export interface TimelineCheckboxItem extends TimelineItemBase {
  type: TimelineItemType.CHECKBOX;
  checked: boolean;
}

export type TimelineItem = CalendarTimelineItem<
  TimelineReservationItem | TimelinePlaceholderItem | TimelineCheckboxItem,
  number
>;

export interface DisableOverlayItem {
  startTime?: number;
  endTime?: number;
  // TODO: Might need to handle per area/table
}

export enum SelectedItemType {
  TIMELINE_ITEM = 'timeline_item',
  TABLE = 'table',
  BLOCKED_AREA = 'blocked_area',
  BLOCKED_TABLE = 'blocked_table',
}

interface SelectedItemBase {
  type: SelectedItemType;
}

export interface SelectedTimelineItem extends SelectedItemBase {
  type: SelectedItemType.TIMELINE_ITEM;
  item: TimelineItem;
  size?: {
    width: number;
    height: number;
  };
}

export interface BasicSelectedByIdItem extends SelectedItemBase {
  type:
    | SelectedItemType.TABLE
    | SelectedItemType.BLOCKED_AREA
    | SelectedItemType.BLOCKED_TABLE;
  id: string | number | null;
}

export type SelectedItem = SelectedTimelineItem | BasicSelectedByIdItem | null;

export enum ReservationChangeType {
  DINING_INTERVAL = 'dining_interval',
  TABLE = 'table',
  RESERVATION_TIME = 'reservation_time',
}

export enum ReservationChangeProblemLevel {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum ReservationChangeProblemType {
  UPDATE_TIME = 'update_time',
  OVERLAP_RESERVATION_TABLE = 'overlap_reservation_table',
  OVERLAP_BLOCK_OUT = 'overlap_block_out',
}

export interface ReservationChangeProblem {
  type: ReservationChangeProblemType;
  level: ReservationChangeProblemLevel;
}

interface ReservationChangeBase {
  type: ReservationChangeType;
}

export interface DiningIntervalReservationChange extends ReservationChangeBase {
  type: ReservationChangeType.DINING_INTERVAL;
  oldValue: number | null;
  newValue: number | null;
}

export interface TableReservationChange extends ReservationChangeBase {
  type: ReservationChangeType.TABLE;
  oldValue: AreaTable[] | null | undefined;
  newValue: AreaTable[] | null | undefined;
}

export interface TimeReservationChange extends ReservationChangeBase {
  type: ReservationChangeType.RESERVATION_TIME;
  oldValue: string;
  newValue: string;
}

export type ReservationChange =
  | DiningIntervalReservationChange
  | TableReservationChange
  | TimeReservationChange;

export interface ConfirmationDialogHandle {
  show: (args: {
    changes: ReservationChange[];
    onConfirm: () => Promise<any>;
    onCancel: () => void;
  }) => void;
  hide: () => void;
}

export interface Filter {
  pax?: number[] | null;
  reservationStatus?: string[] | null;
  reservationTicket?: Ticket | null;
  reservationSearchValue?: string | null;
}

export interface TimelineContextType {
  getTimelineState: () => {
    visibleTimeStart: number;
    visibleTimeEnd: number;
    canvasTimeStart: number;
    canvasTimeEnd: number;
    canvasWidth: number;
    timelineWidth: number;
  };
  getLeftOffsetFromDate: (date: number) => number;
  getDateFromLeftOffsetPosition: (leftOffset: number) => number;
  showPeriod: (from: number, to: number) => void;
}

export interface TimelineChildrenProps {
  canvasTimeStart: number;
  canvasTimeEnd: number;
  canvasWidth: number;
  groupHeights: number[];
  groupTops: number[];
  groups: { id: string }[];
  visibleTimeStart: number;
  visibleTimeEnd: number;
}

export enum TimelineGroupSortingOption {
  PRIORITY = 'priority',
  ALPHABETICAL = 'alphabetical',
}
