import { useMutation, useQuery } from '@tanstack/react-query';
import useV2Api from '@/common/hooks/use-v2-api';
import { ZNote } from '@/common/layouts/top-bar/note-popover/type';
import { z } from 'zod';
import { useMemo } from 'react';

export const useGetNote = (date: string) => {
  const apiV2 = useV2Api();

  const fetchNote = useMemo(
    () =>
      z
        .function()
        .args(z.string())
        .implement(async (date: string) => {
          const { data } = await apiV2.get('/operation-notes/reservation', {
            params: { date },
          });

          return ZNote.parse(data);
        }),
    [apiV2]
  );

  return useQuery(['note-query', date], () => fetchNote(date), {
    initialData: { date, reservationNotes: '' },
  });
};

export type CreateNotePayload = z.infer<typeof ZCreateNotePayload>;

export const ZCreateNotePayload = z.object({
  date: z.string(),
  reservationNotes: z.string(),
});

export const useCreateNote = () => {
  const apiV2 = useV2Api();

  return useMutation(
    z
      .function()
      .args(ZCreateNotePayload)
      .implement(async (newNote: CreateNotePayload) => {
        const { data } = await apiV2.post(
          '/operation-notes/reservation',
          newNote
        );
        return ZNote.parse(data);
      })
  );
};
