import { z } from 'zod';
import useCallbackRef from './use-callback-ref';
import { useRouter } from 'next/router';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { redirect } from '../lib/router';

export const ZViewMode = z.enum(['list', 'timeline', 'floorplan']);
export type ViewMode = z.infer<typeof ZViewMode>;

export const useReservationViewMode = () => {
  const ctx = useContext(ReservationViewModeContext);
  return [ctx.viewMode, ctx.setViewMode] as const;
};

export const ReservationViewModeContext = createContext<{
  viewMode: ViewMode | null;
  setViewMode: (newMode: ViewMode) => void;
}>({ viewMode: null, setViewMode: () => {} });

export const ReservationViewModeProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const getStorageViewMode = useCallbackRef(() => {
    try {
      return ZViewMode.parse(
        JSON.parse(
          localStorage.getItem('useLocalStorage::reservationViewMode') || ''
        )
      );
    } catch {}
    return null;
  });

  const setStorageViewMode = useCallbackRef((newMode: ViewMode) =>
    localStorage.setItem(
      'useLocalStorage::reservationViewMode',
      JSON.stringify(newMode)
    )
  );

  const router = useRouter();

  const routerViewMode = useMemo(() => {
    try {
      return ZViewMode.parse(
        router.query.v ||
          new URLSearchParams(window.location.href.split('?')[1]).get('v')
      );
    } catch {}
    return null;
  }, [router.query.v]);

  const viewMode = routerViewMode || getStorageViewMode();

  const setViewMode = useCallbackRef((newMode: ViewMode) => {
    setStorageViewMode(newMode);
    // Create a new URL object with the current URL
    const url = new URL(window.location.href);

    // Get the current query parameters
    const params = new URLSearchParams(url.search);

    // Set the new view mode
    params.set('v', newMode);

    // Update the URL's search parameters
    url.search = params.toString();

    // Redirect to the new URL
    redirect(url.toString());
  });

  useEffect(() => {
    if (routerViewMode) return setStorageViewMode(routerViewMode);

    const storageViewMode = getStorageViewMode();
    if (storageViewMode) return setViewMode(storageViewMode);

    setViewMode('list');
  }, [routerViewMode, getStorageViewMode, setViewMode, setStorageViewMode]);

  return (
    <ReservationViewModeContext.Provider value={{ viewMode, setViewMode }}>
      {children}
    </ReservationViewModeContext.Provider>
  );
};
