import { useEffect, useMemo, useRef } from 'react';
import styles from './index.module.scss';
import { css } from '@emotion/react';
import {
  Stack,
  Typography,
  alpha,
  useTheme,
  Select,
  MenuItem,
  SelectChangeEvent,
  Divider,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Trans, useTranslation } from 'next-i18next';
import { useReservationViewMode } from '@/common/hooks/use-reservation-view-mode';
import { useAtom, useAtomValue } from 'jotai';

import { Chip } from '@mui/material';
import { timeScheduleSelectAtom } from '@/feat/reservation/state';
import { currentTimeAtom } from '@/feat/reservation/components/gantt-view/state';
import { format, isToday } from 'date-fns';
import { useCurrentTime } from '@/feat/reservation/components/gantt-view/hooks/use-current-time';
import { useTopNavDate } from '@/common/hooks/storage';

interface ItemScheduleSelector {
  value: string;
  label: string;
}
const ScheduleSelector = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const selectedTimeRef = useRef<HTMLLIElement>(null);
  const currentTime = useAtomValue(currentTimeAtom);

  const [scheduleSelect, setScheduleSelect] = useAtom(timeScheduleSelectAtom);

  const [viewMode] = useReservationViewMode();
  const [topNavDate] = useTopNavDate();
  const isNow = isToday(topNavDate);

  const getTimeBlocks = useMemo(() => {
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();
    const dataTimePast = [];
    const dataTimeFuture = [];

    for (let hour = 1; hour <= 23; hour++) {
      // const formattedHour = hour.toString().padStart(2, '0');
      const period = hour < 12 ? 'AM' : 'PM';
      const displayHour = hour <= 12 ? hour : hour - 12;
      const compareTime =
        hour < currentHour ||
        (hour === currentHour && new Date().getMinutes() > 0);

      const timeBlock = {
        // value: `${formattedHour}:00:00`,
        value: `${displayHour}:00 ${period}`,
        label: `${displayHour}:00 ${period}`,
        isCurrentTime: hour === currentHour && currentMinute === 0,
      };

      if (compareTime) {
        dataTimePast.push({ ...timeBlock });
      } else {
        dataTimeFuture.push({ ...timeBlock });
      }
    }

    return { dataTimePast, dataTimeFuture };
  }, []);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setScheduleSelect(event.target.value);
  };

  const formattedTime = format(currentTime, 'h:mm a');

  const getCurrentTimeLabel = useMemo(() => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          padding: theme.spacing(0, 0, 0, 0.75),
          borderRadius: theme.spacing(0.75),
        }}
      >
        <Typography
          variant="body2"
          sx={{
            mr: 2,
          }}
        >
          <Trans>Current Time:</Trans>
        </Typography>

        <Chip
          label={formattedTime}
          color="primary"
          sx={{
            borderRadius: '6px',
            backgroundColor: theme.palette.primary.main_16,
            '& .MuiChip-label': {
              color: theme.palette.primary.main,
              fontWeight: '600',
            },
          }}
        />
      </Stack>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedTime]);

  // Effects
  useCurrentTime();
  useEffect(() => {
    if (viewMode === 'floorplan' && isNow) {
      setScheduleSelect('Current Time');
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = useMemo(() => {
    const mapTimeBlock = (item: ItemScheduleSelector) => ({
      value: item.value,
      label: item.label,
    });

    return viewMode === 'floorplan'
      ? [
          { value: 'All Day', label: t('All Day') },
          ...getTimeBlocks.dataTimePast.map(mapTimeBlock),
          {
            value: 'Current Time',
            label: <Stack sx={{ mx: -1 }}>{getCurrentTimeLabel}</Stack>,
          },
          {
            value: 'divider',
            label: (
              <Divider
                sx={{
                  background: theme.palette.grey[50024],
                  width: '100%',
                }}
              />
            ),
          },
          ...getTimeBlocks.dataTimeFuture.map(mapTimeBlock),
        ]
      : [{ value: 'All Day', label: t('All Day') }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTimeBlocks, viewMode, formattedTime]);

  const handleOpenSelector = () => {
    setTimeout(() => {
      if (selectedTimeRef.current) {
        selectedTimeRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }, 500);
  };

  return (
    <Select
      className={styles['schedule-selector']}
      defaultValue="All Day"
      value={scheduleSelect}
      onChange={handleChange}
      onOpen={handleOpenSelector}
      renderValue={(value) => {
        return <Typography sx={{ fontWeight: 600 }}>{value}</Typography>;
      }}
      IconComponent={KeyboardArrowDownIcon}
      MenuProps={{
        classes: { list: styles.dropdown },
        PaperProps: {
          sx: {
            maxHeight: 400,
          },
        },
        MenuListProps: {
          sx: {
            p: `${theme.spacing(1)} !important`,
          },
        },
      }}
      fullWidth={false}
      css={css`
        .MuiNativeSelect-select,
        .MuiSelect-select {
          padding: 8px 16px;
          padding-right: calc(16px + (24px)) !important;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          height: 100%;
        }
        & > div[aria-expanded='false'] {
          background-color: ${theme.palette.grey[200]};
        }
        & > div[aria-expanded='true'] {
          background-color: ${alpha(theme.palette.primary.main, 0.08)};
        }

        svg.MuiSelect-icon {
          width: 24px;
          height: 24px;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
        }
      `}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          ref={option.value === scheduleSelect ? selectedTimeRef : null}
          sx={{
            ...(option.value === 'divider' && {
              p: theme.spacing(1.25, 0),
              '&:hover': {
                background: theme.palette.common.white,
                cursor: 'default',
              },
            }),
            '&.Mui-selected': {
              backgroundColor: theme.palette.primary.main_16,
              borderRadius: '6px',
              color: theme.palette.primary.main,
            },
            ...(option.value !== 'divider' && {
              '&:hover': {
                color: theme.palette.common.black,
              },
            }),
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ScheduleSelector;
