import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';
import ViewTimelineTwoTone from '@mui/icons-material/ViewTimelineTwoTone';
import ListAltTwoTone from '@mui/icons-material/ListAltTwoTone';
import DashboardTwoTone from '@mui/icons-material/DashboardTwoTone';
import { useState } from 'react';
import { Trans } from 'next-i18next';
import { assertUnreachable } from '@/common/utils';
import NextLink from '@/common/components/next-link';
import { useReservationViewMode } from '@/common/hooks/use-reservation-view-mode';
import { timeScheduleSelectAtom } from '@/feat/reservation/state';
import { useSetAtom } from 'jotai';
import { useTopNavDate } from '@/common/hooks/storage';
import { isToday } from 'date-fns';

const viewModeOptions = [
  {
    label: <Trans>List View</Trans>,
    path: '/reservations?v=list',
    icon: <ListAltTwoTone fontSize="medium" />,
    value: 'list',
  },
  {
    label: <Trans>Timeline View</Trans>,
    path: '/reservations?v=timeline',
    icon: <ViewTimelineTwoTone fontSize="medium" />,
    value: 'timeline',
  },
  {
    label: <Trans>Floor Plan View</Trans>,
    path: '/reservations?v=floorplan',
    icon: <DashboardTwoTone fontSize="medium" />,
    value: 'floorplan',
  },
];

const ViewModeToggle = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [viewMode] = useReservationViewMode();
  const setScheduleSelect = useSetAtom(timeScheduleSelectAtom);
  const [topNavDate] = useTopNavDate();
  const isNow = isToday(topNavDate);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const iconRender = () => {
    if (viewMode === 'list' || viewMode === null)
      return <ListAltTwoTone fontSize="medium" />;
    if (viewMode === 'timeline') return <ViewTimelineTwoTone />;
    if (viewMode === 'floorplan') return <DashboardTwoTone />;

    assertUnreachable(viewMode);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Stack alignItems="center">
          {iconRender()}
          <Typography
            variant="subtitle2"
            color="textSecondary"
            sx={{
              fontSize: '12px',
              lineHeight: '20px',
              textTransform: 'capitalize',
            }}
          >
            <Trans>{viewMode}</Trans>
          </Typography>
        </Stack>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuList>
          {viewModeOptions.map((it, idx) => (
            <MenuItem
              key={idx}
              onClick={() => {
                let defaultScheduleSelect = '';
                if (it.value === 'floorplan' && isNow) {
                  defaultScheduleSelect = 'Current Time';
                } else {
                  defaultScheduleSelect = 'All Day';
                }
                setScheduleSelect(defaultScheduleSelect);
                handleClose();
              }}
              component={NextLink}
              href={it.path}
            >
              <ListItemIcon>{it.icon}</ListItemIcon>
              {it.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};

export default ViewModeToggle;
