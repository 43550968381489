import DateNavigation from '@/common/components/date-navigation';
import NextLink from '@/common/components/next-link';
import { useReservationViewMode } from '@/common/hooks/use-reservation-view-mode';
import useOutletSwitch from '@/feat/auth/use-outlet-switch';
import { GREY } from '@/minimals.cc/theme/palette';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import EventRepeatTwoToneIcon from '@mui/icons-material/EventRepeatTwoTone';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import Paper, { PaperProps } from '@mui/material/Paper';
import { Trans, useTranslation } from 'next-i18next';
import NotePopover from './note-popover';
import ScheduleSelector from './schedule-selector';
import TimelineListViewScheduleSelector from './timeline-listview-schedule-selector';
import { TopBarBtn } from './top-bar-btn';
import ViewModeToggle from './view-mode-toggle';

export default function TopBar({ sx, ...props }: PaperProps) {
  const { selectedOutlet, selectedBrand } = useOutletSwitch();
  const theme = useTheme();
  const [viewMode] = useReservationViewMode();
  const { t } = useTranslation();

  return (
    <TopBarWrapper
      elevation={16}
      sx={{ [theme.breakpoints.down('md')]: { display: 'none' }, ...sx }}
      {...props}
    >
      <Stack
        direction="row"
        spacing="24px"
        sx={{ flex: 1 }}
        justifyContent="space-between"
        alignItems="center"
        css={css`
          padding: 0 16px;
          height: 56px;
        `}
      >
        <div
          css={css`
            flex: 1;
            overflow: hidden;
            & > .MuiTypography-root {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          `}
        >
          <Typography
            variant="subtitle1"
            color="textPrimary"
            css={css`
              font-size: 14px;
              line-height: 22px;
            `}
          >
            {selectedBrand?.brandName}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            css={css`
              font-size: 12px;
              line-height: 18px;
            `}
          >
            {selectedOutlet?.outletName}
          </Typography>
        </div>
        <DateNavigation />
        <TopBarSchedNav>
          {viewMode === 'floorplan' ? (
            <ScheduleSelector />
          ) : (
            <TimelineListViewScheduleSelector />
          )}
          <TopBarBtn
            toolTipTitle={t('Services')}
            LinkComponent={NextLink}
            href="/reservations/online-schedule"
            css={css`
              &:hover {
                background-color: transparent;
              }
              touchaction: 'manipulation';
            `}
          >
            <Stack alignItems="center">
              <EventRepeatTwoToneIcon />
              <Typography
                variant="subtitle2"
                color="textSecondary"
                sx={{
                  fontSize: '12px',
                  lineHeight: '20px',
                }}
              >
                <Trans>Schedules</Trans>
              </Typography>
            </Stack>
          </TopBarBtn>
          <NotePopover />
          <ViewModeToggle />
        </TopBarSchedNav>
      </Stack>
    </TopBarWrapper>
  );
}

export const StoreName = styled(Box)`
  max-width: 380px;
  align-items: center;
  display: flex;
  flex: 0 1 auto;

  > h2 {
    color: ${GREY[800]};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    word-wrap: break-word;
  }
`;

export const TopBarWrapper = styled(Paper)`
  border-radius: 16px;
`;

export const TopBarSchedNav = styled.div`
  display: flex;
  align-items: center;
  min-width: 304px;
  flex: 1;
  justify-content: flex-end;
`;
