export const LINE_HEIGHT = 80;
export const SIDEBAR_WIDTH = 87;
export const SCROLL_ELM_EDGE_OFFSET = 100;
export const INTERVAL_TIME_TO_SCROLL_TOP_BOT = 150;
export const INTERVAL_TIME_TO_SCROLL_LEFT_RIGHT = 50;
export const Z_INDEX = {
  group: 60,
  timelineMarker: 70,
  popOver: 80,
  timelinePointer: 69,
  areaTableBlockOut: 50,
};

const HOUR_IN_MS = 60 * 60 * 1000;

export const WALK_IN_BOUND = 0.25 * HOUR_IN_MS;

export const DEFAULT_START_PADDING = 2 * 60 * 60 * 1000;
export const DEFAULT_END_PADDING = 4 * 60 * 60 * 1000;
export const VISIBLE_HOURS = 6 * 60 * 60 * 1000;

export const DEFAULT_WALK_IN_DURATION = 1.5 * HOUR_IN_MS;
export const DEFAULT_RESERVATION_DURATION = 1.5 * HOUR_IN_MS;

export const QUANTITY_OF_SHOW_PAX_OPTIONS = 10;
export const SELECTED_RESERVATION_HEIGHT = 3;

export const MAX_ZOOM_IN = 1 * 60 * 60 * 1000;
export const ZOOM_FACTORS = {
  IN: 1.1,
  OUT: 0.9,
};
