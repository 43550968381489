import React, { useState } from 'react';
import { SxProps } from '@mui/system';
import { alpha, styled, Theme } from '@mui/material/styles';
import { CalendarPickerProps } from '@mui/x-date-pickers/CalendarPicker/CalendarPicker';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import RoomServiceTwoToneIcon from '@mui/icons-material/RoomServiceTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import { useTranslation } from 'next-i18next';
import LoadingScreen from '@/common/components/loading-screen';
import EventManagement from '@/feat/calendar/event-management';
import { useReservationSummary } from '@/common/hooks/use-reservations';
import {
  Summary,
  SummaryPayload,
} from '@/common/types/monthly-reservation-summary';
import StickyBottomToolbar from '@/common/components/sticky-bottom-toolbar';
import useI18nTimeUtils from '@/common/i18n-time-utils';

interface DateElementProps {
  pickersDayProps: PickersDayProps<Date>;
  event?: Summary;
}

const DateElement: React.FC<DateElementProps> = ({
  pickersDayProps,
  event,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { selected, onDaySelect, day, outsideCurrentMonth } = pickersDayProps;

  const handleClick = () => {
    onDaySelect(day, 'finish');
  };

  const getStatus = (event: Summary | undefined) => {
    if (!event) return undefined;
    const { isBlockOutDate, isSpecial } = event;
    if (isBlockOutDate) {
      const { serviceTimingsBlocked, serviceTimingsRunning } = event;
      const countBlockedServices = serviceTimingsBlocked?.length || 0;
      const countAllServices =
        countBlockedServices + (serviceTimingsRunning?.length || 0);
      return [`${countBlockedServices}/${countAllServices}`, t('Blocked')].join(
        ' '
      );
    }
    if (isSpecial) {
      return t('Special');
    }
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-between',
        alignItems: 'end',
        cursor: 'pointer',
        fontWeight: '500',
        width: 0,
        flex: '1 1 0',
        alignSelf: 'stretch',
        height: 'auto !important',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        background: outsideCurrentMonth
          ? theme.palette.grey[500_16]
          : event && event.isBlockOutDate
          ? `url('/images/background-blocked-date.svg') no-repeat center / cover`
          : '#fff',
        marginRight: '2px',
        ...(selected && {
          border: `2px solid ${theme.palette.primary.main}`,
        }),
        padding: selected ? '8px' : '10px',

        ':last-of-type': {
          marginRight: '0',
        },

        ':hover': {
          background: outsideCurrentMonth
            ? 'linear-gradient(0deg, rgba(61, 0, 153, 0.08), rgba(61, 0, 153, 0.08)), rgba(145, 158, 171, 0.16)'
            : event && event.isBlockOutDate
            ? `linear-gradient(0deg, rgba(61, 0, 153, 0.08), rgba(61, 0, 153, 0.08)), url('/images/background-blocked-date.svg') no-repeat center / cover`
            : 'linear-gradient(0deg, rgba(61, 0, 153, 0.08), rgba(61, 0, 153, 0.08)), #FFFFFF',
        },
      }}
    >
      <PickersDay
        sx={{
          margin: 0,
          fontSize: '14px',
          backgroundColor: 'transparent',

          ':hover': {
            backgroundColor: 'transparent',
          },

          '&.MuiPickersDay-today': {
            background: theme.palette.grey[500_16],
            borderColor: theme.palette.grey[500_24],
            color: '#000',
          },

          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
          },
        }}
        {...pickersDayProps}
      />
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'end',
          alignItems: 'end',
          marginTop: '8px',
          textAlign: 'right',
          flexGrow: 1,
        }}
      >
        {getStatus(event) && (
          <Typography
            sx={{
              display: 'inline-block',
              width: 'fit-content',
              color: theme.palette.info.dark,
              background: alpha(theme.palette.info.main, 0.16),
              borderRadius: '6px',
              padding: '0px 4px 2px 4px',
              fontWeight: '700',
              fontSize: '10px',
              lineHeight: '16px',
            }}
          >
            {getStatus(event)}
          </Typography>
        )}
        {event?.serviceTimingsRunning?.length === 0 &&
          event?.serviceTimingsBlocked?.length === 0 && (
            <Typography
              sx={{
                color: theme.palette.grey[600],
                background: alpha(theme.palette.action.selected, 0.16),
                borderRadius: '6px',
                padding: '0px 4px 2px 4px',
                fontWeight: '500',
                fontSize: '12px',
                lineHeight: '18px',
                marginTop: '4px',
              }}
            >
              {t('No service')}
            </Typography>
          )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            color: theme.palette.action.disabled,
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '18px',
          }}
        >
          <Box sx={{ display: 'flex', height: '18px' }}>
            {event && event.totalReservations > 0 && (
              <Box
                sx={{
                  marginLeft: '6px',
                  marginTop: '4px',
                }}
              >
                <RoomServiceTwoToneIcon
                  sx={{ width: '16px', height: '16px', marginBottom: '-3px' }}
                />
                <span>{event.totalReservations}</span>
              </Box>
            )}
            {event && event.totalAdults + event.totalChildren > 0 && (
              <Box
                sx={{
                  marginLeft: '6px',
                  marginTop: '4px',
                }}
              >
                <PersonOutlineTwoToneIcon
                  sx={{ width: '16px', height: '16px', marginBottom: '-3px' }}
                />
                <span>{event.totalAdults + event.totalChildren}</span>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const StyledCalendar = styled(CalendarPicker<Date>)(({ theme }) => ({
  boxShadow:
    '0px 0px 2px rgba(145, 158, 171, 0.24), 0px 16px 32px -4px rgba(145, 158, 171, 0.24)',
  borderRadius: '16px',
  width: '100%',
  maxHeight: 'unset',
  minWidth: '676px',

  '.MuiDayPicker-header': {
    display: 'flex',
    justifyContent: ' space-around;',
  },
  '>div:first-of-type': {
    margin: '0',
    maxHeight: '40px',
    height: '40px',
    flex: '0 0 auto',
    backgroundColor: '#fff',
    position: 'relative',
    color: theme.palette.text.primary,

    '>div': {
      position: 'absolute',
      top: '8px',

      '&:first-of-type': {
        justifyContent: 'center',
        width: '180px',
        left: 'calc(50% - 90px)',
        cursor: 'default',
        zIndex: 1,

        div: {
          margin: '0',
          backgroundColor: 'transparent',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },

        button: {
          display: 'none',
        },
      },

      '&:last-of-type': {
        width: '240px',
        left: 'calc(50% - 120px)',
        justifyContent: 'space-between',
      },
    },
  },

  '.MuiDayPicker-monthContainer': {
    position: 'unset',
  },

  '.MuiDayPicker-slideTransition': {
    overflowX: 'hidden',
  },

  '>div:last-of-type': {
    backgroundColor: theme.palette.grey[200],
    overflowY: 'hidden',
    height: '100%',

    '>div': {
      height: '100%',
    },

    'div[role=grid]': {
      position: 'relative',
      height: '100%',

      'div[role=row]': {
        justifyContent: 'space-around',

        '&:last-of-type': {
          marginBottom: 0,

          '.MuiBox-root': {
            '&:first-of-type': {
              borderRadius: `0 0 0 16px`,
            },

            '&:last-of-type': {
              borderRadius: `0 0 16px`,
            },
          },
        },
      },

      '>div:last-of-type': {
        height: 'calc(100% - 28px)',

        'div[role=rowgroup]': {
          height: '100%',
          overflowY: 'auto',
          display: 'flex',
          flexFlow: 'column',

          'div[role=row]': {
            flexGrow: 1,
            margin: '1px 0 0 0',

            '&:first-of-type': {
              marginTop: 0,
            },
          },
        },
      },
    },

    '.MuiTypography-caption': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '28px',
      width: '100%',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      backgroundColor: '#fff',
      color: theme.palette.text.primary,
      margin: '0',
      borderBottom: `1px solid rgba(145, 158, 171, 0.24)`,
      borderTop: `1px solid rgba(145, 158, 171, 0.24)`,
    },

    '.PrivatePickersSlideTransition-root': {
      '>div': {
        position: 'relative',
      },
    },
  },
}));

interface CalendarProps<T> extends CalendarPickerProps<T> {
  sx?: SxProps<Theme>;
  onClose?: () => void;
}

const Calendar: React.FC<CalendarProps<Date>> = (props) => {
  const { sx, date, onClose, ...restProps } = props;
  const { t } = useTranslation();
  const { formatDate } = useI18nTimeUtils();
  const theme = useTheme();

  const getSummaryPayloadFromDate = (d: Date | null): SummaryPayload => {
    const formattedDate = d || new Date();
    return {
      year: format(formattedDate, 'yyyy'),
      month: format(formattedDate, 'MM'),
    };
  };

  const [summaryPayload, setSummaryPayload] = useState<SummaryPayload>(
    getSummaryPayloadFromDate(date)
  );

  const { data: events, isFetching } = useReservationSummary({
    params: summaryPayload,
  });

  const getEventByDay = (day: Date): Summary | undefined => {
    const formattedDay = format(day, 'yyyy-MM-dd');
    return events && events.find((event) => event.date === formattedDay);
  };

  const handleMonthChange = (newDate: Date) => {
    setSummaryPayload(getSummaryPayloadFromDate(newDate));
  };

  return (
    <Dialog open fullScreen>
      <DialogContent sx={{ p: 0, background: theme.palette.grey[200] }}>
        <Box
          sx={{
            position: 'relative',
            ...sx,
          }}
        >
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '12px 16px',
              lineHeight: '30px',
              background: theme.palette.grey[200],
              zIndex: 2,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: ' 20px !important',
                lineHeight: '30px',
              }}
            >
              {t('Calendar')}
            </Typography>
            <IconButton
              sx={{
                width: 40,
                height: 40,
                color: theme.palette.action.active,
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Container
            maxWidth="xl"
            sx={{
              display: 'flex',
              width: '100%',
              height: 'calc(100vh - 124px)',
              justifyContent: 'center',
              marginTop: '64px',
              marginBottom: '60px',
              padding: '0 16px 12px 16px !important',
              overflowY: 'auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                width: '100%',
                maxWidth: '1900px',
                maxHeight: '832px',
                minHeight: '464px',
              }}
            >
              <StyledCalendar
                {...restProps}
                sx={{ flexGrow: 1 }}
                date={date}
                showDaysOutsideCurrentMonth={true}
                dayOfWeekFormatter={(nameOfDay) => nameOfDay}
                onMonthChange={handleMonthChange}
                view="day"
                loading={isFetching}
                renderLoading={() => (
                  <LoadingScreen
                    sx={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                )}
                renderDay={(
                  day,
                  selectedDays,
                  props: PickersDayProps<Date>
                ) => (
                  <DateElement
                    key={props.key}
                    pickersDayProps={props}
                    event={getEventByDay(props.day)}
                  />
                )}
              />
              <EventManagement
                date={date || new Date()}
                event={getEventByDay(date || new Date())}
                loading={isFetching}
              />
            </Box>
          </Container>
          <StickyBottomToolbar
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              padding: '16px !important',
            }}
          >
            <Button sx={{ padding: '11px 22px' }} onClick={onClose}>
              {date
                ? t('View {{date}} Reservations', {
                    date: formatDate(date, { en: 'd MMM', zh: 'MMM do' }),
                  })
                : t('View Reservations')}
            </Button>
          </StickyBottomToolbar>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Calendar;
