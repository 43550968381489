import { useAtom } from 'jotai';
import { currentTimeAtom } from '../state';
import { useEffect } from 'react';
import { getUnixTimeMs } from '@/common/lib/date-time';

const UPDATE_INTERVAL = 1000 * 10;

export const useCurrentTime = () => {
  const [currentTime, setCurrentTime] = useAtom(currentTimeAtom);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(getUnixTimeMs(new Date()));
    }, UPDATE_INTERVAL);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [setCurrentTime]);

  return currentTime;
};
