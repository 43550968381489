import { PrimaryButton, SecondaryButton } from '@/common/components/buttons';
import { useTopNavDate } from '@/common/hooks/storage';
import {
  useCreateNote,
  useGetNote,
} from '@/common/layouts/top-bar/note-popover/use-note';
import { css } from '@emotion/react';
import MarkUnreadChatAltTwoToneIcon from '@mui/icons-material/MarkUnreadChatAltTwoTone';
import ChatTwoToneIcon from '@mui/icons-material/ChatTwoTone';
import {
  Box,
  CircularProgress,
  Grid,
  Popover,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { Trans, useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { TopBarBtn } from '../top-bar-btn';

const NotePopover = (): JSX.Element => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [topNavDate] = useTopNavDate();

  const selectedDate = useMemo(
    () => format(topNavDate, 'yyyy-MM-dd'),
    [topNavDate]
  );

  const {
    data: { reservationNotes: note },
    isLoading: isQueryLoading,
    isFetching: isQueryFetching,
  } = useGetNote(selectedDate);

  const { mutate, isLoading: isMutationLoading } = useCreateNote();

  const [openEl, setOpenEl] = useState<HTMLElement | null>(null);
  const [editable, setEditable] = useState(false);
  const [newNote, setNewNote] = useState<string>('');

  useEffect(() => {
    setNewNote(note);
  }, [note]);

  const isLoading = isQueryLoading || isQueryFetching || isMutationLoading;

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpenEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenEl(null);
    setEditable(false);
  };

  const handleSave = () => {
    mutate(
      { date: selectedDate, reservationNotes: newNote },
      {
        onSettled: () => {
          setEditable(false);
        },
        onSuccess: async () =>
          queryClient.invalidateQueries(['note-query', selectedDate]),
      }
    );
  };

  const isShowNote = !!note && !editable && !isLoading;

  const isShowTextField = editable || !note || isLoading;

  return (
    <>
      <TopBarBtn
        className="NoteBtn-root"
        active={!!openEl}
        onClick={handleOpen}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Stack alignItems="center">
          {note ? (
            <MarkUnreadChatAltTwoToneIcon
              style={{ color: palette.primary.main }}
            />
          ) : (
            <ChatTwoToneIcon />
          )}
          <Typography
            variant="subtitle2"
            color="textSecondary"
            sx={{
              fontSize: '12px',
              lineHeight: '20px',
              color: note ? '' : 'palette.primary.main',
            }}
          >
            <Trans>Notes</Trans>
          </Typography>
        </Stack>
      </TopBarBtn>

      <Popover
        open={Boolean(openEl)}
        anchorEl={openEl}
        onClose={handleClose}
        sx={{ p: 0 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: '24px',
            pt: '24px',
            ml: '24px',
            width: '465px',
            overflow: 'inherit',
          },
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h6">
              <Trans>Notes</Trans>
            </Typography>
          </Grid>

          <Grid item>
            {editable ? (
              <PrimaryButton onClick={handleSave} sx={{ height: 36 }}>
                {isMutationLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Trans>Save</Trans>
                )}
              </PrimaryButton>
            ) : (
              <SecondaryButton onClick={() => setEditable((prev) => !prev)}>
                <Trans>Edit</Trans>
              </SecondaryButton>
            )}
          </Grid>
        </Grid>

        <Box>
          {isShowNote && (
            <Typography
              css={css`
                word-break: break-word;
                margin-top: 16px;
              `}
              variant="body1"
            >
              {note}
            </Typography>
          )}

          {isShowTextField && (
            <>
              <TextField
                css={css`
                  height: 100%;
                  width: 100%;
                  margin-top: 16px;
                `}
                multiline={true}
                rows={4}
                InputProps={{
                  style: {
                    padding: '16px',
                  },
                }}
                inputProps={{
                  maxLength: 120,
                }}
                disabled={!editable || isLoading}
                placeholder={
                  editable ? '' : t('Click on “Edit” to start adding notes.')
                }
                defaultValue={note}
                onChange={(e) => setNewNote(e.target.value)}
              />

              {editable && !isLoading && (
                <Stack direction="row" justifyContent="end">
                  <Typography
                    sx={{ color: 'text.secondary', ml: 0 }}
                    variant="caption"
                  >
                    <Trans>{{ length: newNote.length }}/120 characters</Trans>
                  </Typography>
                </Stack>
              )}
            </>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default NotePopover;
