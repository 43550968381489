import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { addDays, isToday } from 'date-fns';
import {
  IconButton,
  IconButtonProps,
  NoSsr,
  Stack,
  StackProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { css } from '@emotion/react';
import { useTranslation } from 'next-i18next';
import { GREY } from '@/minimals.cc/theme/palette';
import { useTopNavDate } from '@/common/hooks/storage';
import { useMemo, useState } from 'react';
import Calendar from '@/feat/calendar/calendar';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { useReservationViewMode } from '@/common/hooks/use-reservation-view-mode';
import { useAtom } from 'jotai';
import { timeScheduleSelectAtom } from '@/feat/reservation/state';

const ArrowBtn = ({ ...props }: IconButtonProps) => (
  <IconButton
    size="medium"
    css={css`
      background-color: transparent;
      padding: 0;
      width: 40px;
      height: 40px;
      text-align: center;
      min-width: inherit;

      &:hover {
        background-color: transparent;
      }
    `}
    {...props}
  />
);

const DateNavigation = (props: StackProps) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const { t } = useTranslation();
  const { formatDate } = useI18nTimeUtils();
  const [topNavDate, setTopNavDate] = useTopNavDate();
  const [viewMode] = useReservationViewMode();
  const [scheduleSelect, setScheduleSelect] = useAtom(timeScheduleSelectAtom);

  const [showCalendar, setShowCalendar] = useState(false);
  const isNow = useMemo(() => {
    if (viewMode !== 'floorplan') return isToday(topNavDate);
    if (
      isToday(topNavDate) &&
      ['All Day', 'Current Time'].includes(scheduleSelect)
    )
      return true;
    return false;
  }, [scheduleSelect, topNavDate, viewMode]);

  const handleChangeScheduleSelector = (isToday: boolean) => {
    if (viewMode !== 'floorplan') return;
    setScheduleSelect(isToday ? 'Current Time' : 'All Day');
  };

  const handleChangeDay = (isNextDay?: boolean) => {
    const changedDate = addDays(topNavDate, isNextDay ? 1 : -1);
    setTopNavDate(changedDate);
    handleChangeScheduleSelector(isToday(changedDate));
  };

  const handleChangeToday = () => {
    if (isNow) {
      if (viewMode !== 'floorplan') return;
      setScheduleSelect('Current Time');
      return;
    }
    setTopNavDate(new Date());
    handleChangeScheduleSelector(true);
  };

  return (
    <NoSsr>
      {showCalendar && (
        <Calendar
          date={topNavDate}
          onChange={(newDate) => {
            if (!newDate) return;
            setTopNavDate(newDate);
          }}
          onClose={() => setShowCalendar(false)}
        />
      )}
      <Stack
        direction="row"
        spacing={1}
        flex="1"
        justifyContent="center"
        {...props}
      >
        <Stack spacing={0} direction="row" alignItems="center">
          <ArrowBtn onClick={() => handleChangeDay(false)}>
            <ChevronLeftIcon />
          </ArrowBtn>
          <Button
            css={css`
              &,
              &.Mui-disabled {
                font-weight: 600;
                background-color: ${GREY[200]};
                color: ${GREY[800]};
              }
              min-width: 120px;
              min-height: 40px;
              &:hover {
                background-color: ${GREY[300]};
              }
            `}
            disabled={isMobile}
            component="div"
            onClick={() => setShowCalendar(true)}
          >
            {formatDate(topNavDate, {
              en: 'E, d MMM',
              zh: 'E, MMM do',
            })}
          </Button>
          <ArrowBtn onClick={() => handleChangeDay(true)}>
            <ChevronRightIcon />
          </ArrowBtn>
        </Stack>
        <Button
          onClick={handleChangeToday}
          css={css`
            position: relative;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            padding: 8px 16px 8px 40px;
            background-color: ${isNow ? '#EAFACE' : GREY[200]};
            color: ${isNow ? '#277C09' : GREY[500_80]};

            &:hover {
              background-color: ${isNow ? '#EAFACE' : GREY[200]};
              color: ${isNow ? '#277C09' : GREY[500_80]};
            }
            &::before {
              background-color: ${isNow ? '#277C09' : GREY[500_80]};
              content: '';
              position: absolute;
              top: 50%;
              left: 18px;
              transform: translateY(-50%);
              width: 12px;
              height: 12px;
              border-radius: 100%;
              transition: inherit;
            }
          `}
        >
          {t('Now')}
        </Button>
      </Stack>
    </NoSsr>
  );
};

export default DateNavigation;
